import { Themes } from 'context/theme-context';
import { CoinGeckoPrices } from 'hooks/useCoingecko';
import { Asset } from '@oraichain/oraidex-contracts-sdk';
import { OrderDirection } from '@oraichain/oraidex-contracts-sdk/build/OraiswapLimitOrder.types';

export type PriceAmountFilled = {
  price: string;
  amount: string;
};

export interface ConfigState {
  address: string;
  statusChangeAccount: boolean;
  theme: Themes;
  coingecko: CoinGeckoPrices<string>;
  persistVersion: number;
}

export interface RecentlyTraded {
  pair: string;
  hash: string;
  height: number;
  time: string;
  orderId: number;
  status: string;
  direction: DirectionTrade;
  bidderAddr: string;
  offerAmount: number;
  askAmount: number;
  filledOfferAmount: number;
  filledAskAmount: number;
  orderType: 'limit' | 'market';
  rewardFee: string;
  relayerFee: string;
}

export interface Orderbook {
  id: number | string;
  price: string;
  ask_amount: string;
  offer_amount: string;
  side: OrderSide;
  status: number;
  time: string;
}

export type IconInfoType = {
  logoDark: string;
  logoLight: string;
};

export type PairToken = {
  id: string | number;
  from: string;
  to: string;
  slippage: string;
  symbol: string;
  info: string;
  route: string;
  price_decimal?: number;
  iconFrom?: IconInfoType;
  iconTo?: IconInfoType;
};

export interface TradingState {
  listToken: PairToken[];
  listTokenFilter: PairToken[];
  currentToken: PairToken | null;
  dataRecentlyTraded: RecentlyTraded[];
  dataSellOrderbook: OrderDetailFromBE[];
  dataBuyOrderbook: OrderDetailFromBE[];
  originalBuyOrders: any[];
  originalSellOrders: any[];
  valueChartMove: ValueChartMove | null;
  currentPrice: string;
  loadingList: boolean;
}

export interface CandlestickMove {
  type: 'up' | 'down';
  close: number;
  high: number;
  low: number;
  open: number;
  time: string | object;
  ratio: {
    num: number;
    percent: number;
  };
}

export interface HistogramMove {
  value: number;
  color?: string;
  time: string | object;
}
export interface ValueChartMove {
  candlestick: CandlestickMove;
  histogram: HistogramMove;
}
export interface TypeDecimal {
  key: string;
  title: string;
}

export enum BuySellFilter {
  sell = 'sell',
  buy = 'buy',
  all = 'all'
}
export interface OrderDetail {
  id: number | string;
  price: string;
  ask_amount: string;
  offer_amount: string;
  side: OrderSide;
  status: number;
  time: string;
  trade_sequence: number;
  fee: string;
}

export interface OrderDetailFromContract {
  order_id: number;
  direction: OrderDirection;
  bidder_addr: string;
  offer_asset: Asset;
  ask_asset: Asset;
  filled_offer_amount: string;
  filled_ask_amount: string;
  amount?: string;
  price?: string;
  priceKey?: string;
  prices?: number[];
}
export interface OrderDetailFromBE {
  amount?: string;
  price?: string;
  priceKey?: number;
  prices?: number[];
  total: string | number;
}

export interface Order {
  orders: OrderDetail[];
}
export interface OrderResponseContract {
  orders: OrderDetailFromContract[];
}

export enum OrderStatus {
  OPEN = 'OPEN',
  FUL_FILLED = 'FUL_FILLED',
  FILLING = 'FILLING',
  CLOSE = 'CLOSE',
  CANCELED = 'CANCELED'
}

export enum TradeStatus {
  FULFILLED = 'Fulfilled',
  PARTIAL_FILLED = 'PartialFilled',
  CANCEL = 'Cancel',
  OPEN = 'Open'
}

export enum OrderSide {
  SELL = 1,
  BUY = 2
}

export enum DirectionTrade {
  SELL = 'Sell',
  BUY = 'Buy'
}
