import {
  LogoAiriDark,
  LogoAiriLight,
  LogoAtomDark,
  LogoAtomLight,
  LogoKwtDark,
  LogoKwtLight,
  LogoMilkyDark,
  LogoMilkyLight,
  LogoOraiDark,
  LogoOraiLight,
  LogoOraixDark,
  LogoOraixLight,
  LogoOsmoDark,
  LogoOsmoLight,
  LogoScoraiDark,
  LogoScoraiLight,
  LogoUsdcDark,
  LogoUsdcLight,
  LogoUsdtDark,
  LogoUsdtLight,
  XochLightIcon,
  XochDarkIcon,
  Bitcoin
} from 'assets/icons';
import { COSMOS_DECIMALS, ORAI } from './constants';
import {
  AIRI_CONTRACT,
  ATOM_ORAICHAIN_DENOM,
  BTC_CONTRACT,
  KWT_CONTRACT,
  MILKY_CONTRACT,
  ORAIX_CONTRACT,
  OSMOSIS_ORAICHAIN_DENOM,
  SCORAI_CONTRACT,
  USDC_CONTRACT,
  USDT_CONTRACT,
  XOCH_CONTRACT
} from '@oraichain/oraidex-common';
import { PairToken } from 'redux/reducers/type';

export const infoTokens = [
  {
    keyAmount: 'orai',
    name: 'ORAI',
    denom: ORAI,
    decimals: COSMOS_DECIMALS,
    isNativeToken: true,
    logoDark: LogoOraiDark,
    logoLight: LogoOraiLight,
    coinGeckoId: 'oraichain-token'
  },
  {
    keyAmount: ATOM_ORAICHAIN_DENOM,
    name: 'ATOM',
    denom: ATOM_ORAICHAIN_DENOM,
    decimals: COSMOS_DECIMALS,
    isNativeToken: true,
    logoDark: LogoAtomDark,
    logoLight: LogoAtomLight,
    coinGeckoId: 'cosmos'
  },
  {
    keyAmount: OSMOSIS_ORAICHAIN_DENOM,
    name: 'OSMO',
    denom: OSMOSIS_ORAICHAIN_DENOM,
    decimals: COSMOS_DECIMALS,
    isNativeToken: true,
    logoDark: LogoOsmoDark,
    logoLight: LogoOsmoLight,
    coinGeckoId: 'osmosis'
  },
  {
    keyAmount: 'airi',
    name: 'AIRI',
    denom: AIRI_CONTRACT,
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: LogoAiriDark,
    logoLight: LogoAiriLight,
    coinGeckoId: 'airight'
  },
  {
    keyAmount: 'oraix',
    name: 'ORAIX',
    denom: ORAIX_CONTRACT,
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: LogoOraixDark,
    logoLight: LogoOraixLight,
    coinGeckoId: 'oraidex'
  },
  {
    keyAmount: 'usdt',
    name: 'USDT',
    denom: USDT_CONTRACT,
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: LogoUsdtDark,
    logoLight: LogoUsdtLight,
    coinGeckoId: 'tether'
  },
  {
    keyAmount: 'kwt',
    name: 'KWT',
    denom: KWT_CONTRACT,
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: LogoKwtDark,
    logoLight: LogoKwtLight,
    coinGeckoId: 'kawaii-islands'
  },
  {
    keyAmount: 'milky',
    name: 'MILKY',
    denom: MILKY_CONTRACT,
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: LogoMilkyDark,
    logoLight: LogoMilkyLight,
    coinGeckoId: 'milky-token'
  },
  {
    keyAmount: 'scorai',
    name: 'scORAI',
    denom: SCORAI_CONTRACT,
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: LogoScoraiDark,
    logoLight: LogoScoraiLight,
    coinGeckoId: 'scorai'
  },
  {
    keyAmount: 'usdc',
    name: 'USDC',
    denom: USDC_CONTRACT,
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: LogoUsdcDark,
    logoLight: LogoUsdcLight,
    coinGeckoId: 'usd-coin'
  },
  {
    keyAmount: 'xoch',
    name: 'xOCH',
    denom: XOCH_CONTRACT,
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: XochDarkIcon,
    logoLight: XochLightIcon,
    coinGeckoId: 'xoch'
  },
  {
    keyAmount: 'btc',
    name: 'BTC',
    denom: BTC_CONTRACT,
    decimals: COSMOS_DECIMALS,
    isNativeToken: false,
    logoDark: Bitcoin,
    logoLight: Bitcoin,
    coinGeckoId: 'bitcoin'
  }
];

export const DATA_PAIRS: PairToken[] = [
  {
    id: 1,
    symbol: 'ORAI/USDT',
    slippage: '0.0000000000000000', // TODO: remove
    from: 'orai',
    to: USDT_CONTRACT,
    price_decimal: 6,
    info: 'orai - ' + USDT_CONTRACT,
    route: '/ORAI_USDT',
    iconFrom: {
      logoDark: LogoOraiDark,
      logoLight: LogoOraiLight
    },
    iconTo: {
      logoDark: LogoUsdtDark,
      logoLight: LogoUsdtLight
    }
  },
  {
    id: 2,
    symbol: 'xOCH/USDT',
    slippage: '0.0000000000000000', // TODO: remove
    from: XOCH_CONTRACT,
    to: USDT_CONTRACT,
    price_decimal: 6,
    info: `${XOCH_CONTRACT} - ${USDT_CONTRACT}`,
    route: '/xOCH_USDT',
    iconFrom: {
      logoDark: XochDarkIcon,
      logoLight: XochLightIcon
    },
    iconTo: {
      logoDark: LogoUsdtDark,
      logoLight: LogoUsdtLight
    }
  },
  {
    id: 4,
    symbol: 'BTC/USDT',
    slippage: '0.0000000000000000', // TODO: remove
    from: BTC_CONTRACT,
    to: USDT_CONTRACT,
    price_decimal: 6,
    info: `${BTC_CONTRACT} - ${USDT_CONTRACT}`,
    route: '/BTC_USDT',
    iconFrom: {
      logoDark: Bitcoin,
      logoLight: Bitcoin
    },
    iconTo: {
      logoDark: LogoUsdtDark,
      logoLight: LogoUsdtLight
    }
  }
];
