import { TokenItemType } from 'config/bridgeTokens';
import { HIGH_GAS_PRICE, MULTIPLIER } from 'config/constants';
import { network } from 'config/networks';
import React from 'react';

import { fromBech32, toBech32 } from '@cosmjs/encoding';
import { CosmosWalletType, WALLET_ENUM } from 'components/ConnectWallet/constants';
import { displayToast, TToastType } from 'components/Toasts';
import { MetamaskOfflineSigner } from 'libs/eip191';

import { ChainIdEnum, chainInfos } from '@oraichain/oraidex-common';
import { isMobile } from 'libs/utils';

export type NetworkType = {
  title: string;
  chainId: string | number;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  networkType: string;
};

export const cosmosNetworks = chainInfos.filter(
  (c) => c.networkType === 'cosmos' && c.chainId !== ChainIdEnum.OraiBridge
);

export const getNetworkGasPrice = async (): Promise<number> => {
  try {
    const chainInfosWithoutEndpoints = await window.Keplr?.getChainInfosWithoutEndpoints();
    const findToken = chainInfosWithoutEndpoints.find((e) => e.chainId == network.chainId);
    if (findToken) {
      return findToken.feeCurrencies[0].gasPriceStep.average;
    }
  } catch {}
  return 0;
};

//hardcode fee
export const feeEstimate = (tokenInfo: TokenItemType, gasDefault: number) => {
  if (!tokenInfo) return 0;
  return (gasDefault * MULTIPLIER * HIGH_GAS_PRICE) / 10 ** tokenInfo?.decimals;
};

export const feeEstimateDefault = (tokenDecimal: number, gasDefault: number) => {
  return (gasDefault * MULTIPLIER * HIGH_GAS_PRICE) / 10 ** tokenDecimal;
};

export const handleCheckWallet = async () => {
  // @ts-ignore
  const isCheckOwallet = window.owallet?.isOwallet;
  const version = window?.keplr?.version;
  const isCheckKeplr = !!version && keplrCheck('keplr');
  const isMetamask = window?.ethereum?.isMetaMask;

  if (!isMobile() && !isCheckOwallet && !isCheckKeplr && !isMetamask) {
    displayInstallWallet();
    return false;
  }

  return true;
};

/**
 * Returns the number rounded to the nearest interval.
 * Example:
 *
 *   roundToNearest(1000.5, 1); // 1000
 *   roundToNearest(1000.5, 0.5);  // 1000.5
 *
 * @param {number} value    The number to round
 * @param {number} interval The numeric interval to round to
 * @return {number}
 */
export const roundToNearest = (value: number, interval: number) => {
  return Math.floor(value / interval) * interval;
};

export const formatNumber = (arg: number): string => {
  return new Intl.NumberFormat('en-US').format(arg);
};

export const formatPrice = (arg: number, typeDecimal: number = 2): string => {
  return arg.toLocaleString('en', {
    useGrouping: true,
    // minimumFractionDigits: typeDecimal
    maximumFractionDigits: typeDecimal
  });
};

export const displayInstallWallet = (altWallet = 'Keplr or Metamask') => {
  displayToast(
    TToastType.TX_INFO,
    {
      message: `You need to install OWallet or ${altWallet} to continue.`,
      customLink: 'https://chrome.google.com/webstore/detail/owallet/hhejbopdnpbjgomhpmegemnjogflenga',
      textLink: 'View on store'
    },
    {
      toastId: 'install_keplr'
    }
  );
};

export const handleCheckAddress = async (): Promise<string> => {
  const oraiAddress = await window.Keplr.getKeplrAddr();
  if (!oraiAddress) {
    throw new Error('Please login keplr!');
  }
  return oraiAddress;
};

export const convertTimestampToTime = ({
  timestamp,
  symbol,
  isUTC,
  hasDate
}: {
  timestamp: number;
  symbol?: string;
  isUTC?: boolean;
  hasDate?: boolean;
}): string => {
  const milliseconds = timestamp * 1000;
  const dateTime = new Date(milliseconds);
  symbol = symbol ?? '/';

  if (isUTC) {
    const hour = dateTime.getUTCHours() > 9 ? dateTime.getUTCHours() : '0' + dateTime.getUTCHours();
    const minute = dateTime.getUTCMinutes() > 9 ? dateTime.getUTCMinutes() : '0' + dateTime.getUTCMinutes();
    const second = dateTime.getUTCSeconds() > 9 ? dateTime.getUTCSeconds() : '0' + dateTime.getUTCSeconds();
    return hour + symbol + minute + symbol + second;
  }
  const year = dateTime.getFullYear();
  const month = ('0' + (dateTime.getMonth() + 1)).slice(-2);
  const day = ('0' + dateTime.getDate()).slice(-2);
  const hours = ('0' + dateTime.getHours()).slice(-2);
  const minutes = ('0' + dateTime.getMinutes()).slice(-2);
  const seconds = ('0' + dateTime.getSeconds()).slice(-2);
  if (hasDate) {
    return `${year}${symbol}${month}${symbol}${day} ${hours}:${minutes}:${seconds}`;
  } else {
    return `${hours}:${minutes}:${seconds}`;
  }
};

export const getUTCTimestamp = (periodTimestamp: number = 0, nowDate: Date = new Date()): number => {
  const secondsPerDay = 86400;
  const currentSecondTime = Math.floor(nowDate.getTime() / 1000);
  return currentSecondTime + periodTimestamp * secondsPerDay;
};

export const handleErrorTransaction = (error: any) => {
  let finalError = '';
  if (typeof error === 'string' || error instanceof String) {
    finalError = error as string;
  } else {
    if (error?.ex?.message) finalError = String(error.ex.message);
    else if (error?.message) finalError = String(error?.message);
    else finalError = String(error);
  }
  displayToast(TToastType.TX_FAILED, {
    message: finalError
  });
};

export function roundUnalteredDecimal(num: number, decimal: number = 3): number {
  const valueSplit = num.toString().split('.');
  if (valueSplit.length === 1) {
    return num;
  }
  if (valueSplit.length === 2) {
    const roundValue = valueSplit[0] + '.' + valueSplit[1].slice(0, decimal);
    return Number(roundValue);
  }
}

export const getAddress = (addr, prefix: string) => {
  if (!addr) return '';
  const { data } = fromBech32(addr);
  return toBech32(prefix, data);
};

export const getAddressByEIP191 = async (isSwitchWallet?: boolean) => {
  const metamaskOfflineSinger = await MetamaskOfflineSigner.connect(window.ethereum);
  if (!metamaskOfflineSinger) return;
  const accounts = await metamaskOfflineSinger.getAccounts(isSwitchWallet);
  return accounts[0].address;
};

export const keplrCheck = (type: CosmosWalletType) => {
  //@ts-ignore
  return type === 'keplr' && window.keplr && window.keplr.mode === 'extension' && !window?.keplr?.isOwallet;
};

export const owalletCheck = (type: CosmosWalletType) => {
  //@ts-ignore
  return type === 'owallet' && window.owallet?.isOwallet;
};

export const isUnlockMetamask = async (): Promise<boolean> => {
  const ethereum = window.ethereum;
  if (!ethereum || !ethereum.isMetaMask || !ethereum._metamask) return false;
  return await window.ethereum._metamask.isUnlocked();
};

export const getActiveStatusWallet = () => {
  // @ts-ignore
  const isCheckOwallet = window.owallet?.isOwallet;
  const version = window?.keplr?.version;
  const isCheckKeplr = !!version && keplrCheck('keplr');
  const isMetamask = window?.ethereum?.isMetaMask;

  return {
    [WALLET_ENUM.OWALLET]: isCheckOwallet || isMobile(),
    [WALLET_ENUM.KEPLR]: isCheckKeplr,
    [WALLET_ENUM.EIP191]: isMetamask
  };
};
